<template>
  <div class="home-banner" >
    <div class="swiper-container home-swiper" v-if="list.length > 0">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item,index) in list" :key="index">
          <a :href="item.url || 'javascript:;'" class="banner">
            <img :src="item.photo" class="fit-img" alt="">
          </a>
        </div>
      </div>
      <div class="home-pagination"></div>
    </div>
  </div>
</template>

<script>
  import 'swiper/css/swiper.min.css'
  import Swiper from 'swiper'

  export default {
    props: {
      list: {}
    },
    mounted () {
      console.log(this.list)
      this.initSwiper()
    },
    data() {
      return {
        swiperOptions: {
          
        },
        homeSwiper: null
      }
    },
    methods: {
      initSwiper() {
        setTimeout(() => {
          this.homeSwiper = new Swiper('.home-swiper', {
            loop: true,
            pagination: {
              el: '.home-pagination',
            },
          })
        },1000)
        
      }
    }
  }
</script>

<style lang="less" scoped>
  .home-banner {
    position: relative;
    height: 40vw;
    .banner {
      display: block;
      width: 92vw;
      height: 40vw;
      margin: 0 auto;
      img {
        border-radius: 1.6vw;
      }
    }
  }
</style>
<style lang="less">
  .home-pagination {
    position: absolute;
    bottom: 2.6666vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    .swiper-pagination-bullet {
      width: 1.8666vw;
      height: 0.5333vw;
      border-radius: 1vw;
      opacity: .47;
      background-color: #FFFFFF;
      transition: .2s
    }
    .swiper-pagination-bullet-active {
      width: 3.3333vw;
      height: 0.8vw;
      background-color: #FFFFFF;
      opacity: 1
    }
  }
</style>